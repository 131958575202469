<template>
  <AppTableControl
    no-filter
    no-export
    @query-debounced="searchQuery"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "LeaveDetailsTableControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeCompanyLeaveListBaseFilter", {
      base: null,
    });
  },
  methods: {
    /**
     * Search the base query.
     *
     * @param baseQuery
     */
    searchQuery(baseQuery) {
      this.$store.dispatch("changeCompanyLeaveListBaseFilter", {
        base: baseQuery,
      });

      this.$store.dispatch("fetchCompanyLeaveList");
    },
  },
};
</script>

<style scoped></style>
