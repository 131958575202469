<template>
  <div>
    <b-table
      ref="leave-details-page-dt"
      id="leave-details-page-dt"
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center"
      show-empty
      :busy="isBusy"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(paid_leave)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.is_paid_leave ? "Yes" : "No" }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showLeaveDetails(data)"
            v-b-tooltip.hover="$t('LEAVE.HOVER.EDIT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->

          <!-- end::: delete-leave button -->
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteLeaveDetails(data)"
            v-b-tooltip.hover="$t('LEAVE.HOVER.DELETE')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-leave button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty="">
        <AppEmptyList
          text-top="No Leaves added"
          text-bottom="You have no created Leave. To add new leave for your company, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="leave-details-page-dt"
        @change="handlePageChange"
        size="lg"
      ></b-pagination>
    </div>
    <LeavePageDetailsModal></LeavePageDetailsModal>
  </div>
</template>

<script>
import LeavePageDetailsModal from "@/modules/company/components/leave/type/LeavePageDetailsModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import { mapGetters } from "vuex";

export default {
  name: "LeavePageDetailsTable",
  props: ["reload"],
  components: {
    AppEmptyList,
    LeavePageDetailsModal,
    AppLoader,
  },
  beforeMount() {
    this.fetch();
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("JOB.EMPTYPE") },
        { key: "description", label: this.$t("DETAILSTABLE.DESC") },
        { key: "paid_leave", label: this.$t("DETAILSTABLE.PAID") },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
      isBusy: false,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchCompanyLeaveList")
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(value) {
      console.log(value);
      this.$store.commit("setCompanyLeaveListPage", value);
      this.fetch();
    },

    showLeaveDetails(row) {
      console.log("Selected Leave ", row);

      // Set the selected company leave
      this.$store.dispatch("selectCompanyLeave", row.item);
      this.$store.dispatch("fetchCompanyLeaveForm", row.item.id);
      this.$store.commit("setUpdateCompanyLeaveTypeForm", false);

      this.$bvModal.show("leave-page-details-modal");
    },

    /**
     * Attempt to delete leave details.
     *
     * @param row
     */
    deleteLeaveDetails(row) {
      const id = row.item.id;
      this.$bvModal
        .msgBoxConfirm(`Confirm delete ${row.item.name}?`, { centered: true })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("deleteCompanyLeaveType", id)
              .then(() => {
                this.fetch();
                this.$bvModal.msgBoxOk(`Deleted ${row.item.name}`, {
                  centered: true,
                });
              })
              .catch(() =>
                this.$bvModal.msgBoxOk(`Failed to delete ${row.item.name}`, {
                  centered: true,
                  okVariant: "danger",
                })
              );
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      items: "getCompanyLeaveList",
      pagination: "getCompanyLeaveListPagination",
      isSaved: "getUpdateCompanyLeaveTypeForm",
    }),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
    isSaved(newState) {
      // If Form is Saved, load Table
      if (newState) {
        this.fetch();
      }
    },
  },
};
</script>

<style scoped></style>
