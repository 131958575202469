<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <b-form class="form" v-on:submit.prevent="createLeave">
      <!-- begin:Name  -->
      <b-form-group :label="$t('DETAILSFORM.TYPE')">
        <b-form-input
          class="form-control h-auto"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          :placeholder="$t('DETAILSFORM.TYPE1')"
        >
        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.name.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:description  -->
      <b-form-group :label="$t('DETAILSFORM.DESC')">
        <b-form-textarea
          class="form-control h-auto"
          :placeholder="$t('DETAILSFORM.DESC1')"
          rows="3"
          max-rows="6"
          v-model="$v.form.description.$model"
          :state="validateState('description')"
        >
        </b-form-textarea>
        <b-form-invalid-feedback v-if="!$v.form.description.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:leave unit  -->
      <b-form-group :label="$t('DETAILSFORM.UNIT')">
        <b-form-select
          v-model="$v.form.leaveunit.$model"
          :options="leaveunits"
          :state="validateState('leaveunit')"
        >
        </b-form-select>
        <b-form-invalid-feedback v-if="!$v.form.leaveunit.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end:leave unit  -->

      <!-- begin: unit  -->
      <b-form-group label="Unit">
        <b-form-input
          type="number"
          v-model="$v.form.unit.$model"
          :state="validateState('unit')"
        >
        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.unit.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end: unit  -->

      <b-form-group :label="$t('DETAILSFORM.PAID')">
        <b-form-checkbox
          inline
          v-model="$v.form.paid.$model"
          value="true"
          unchecked-value="false"
          >{{ $t("BUTTON.YES") }}</b-form-checkbox
        >
        <b-form-invalid-feedback v-if="!$v.form.paid.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <b-button
          variant="primary"
          type="submit"
          ref="department-form-submit"
          class="font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  minLength,
  required,
  numeric,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "LeavePageDetailsForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Leave Type Form",
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        leaveunit: "",
        unit: "",
        paid: "",
      },
      leaveunits: [
        { value: "1", text: "Day" },
        { value: "2", text: "Hour" },
      ],
      daycounts: [
        { value: "1", text: "Workday" },
        { value: "2", text: "Calendar Day" },
      ],
      isBusy: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      unit: {
        required,
        numeric,
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      leaveunit: {
        required,
      },
      paid: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    createLeave() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      console.log("Form is", this.form);

      const form = {
        name: this.form.name,
        description: this.form.description,
        leave_unit: this.form.leaveunit,
        paid_leave: this.form.paid === "true",
        unit: this.form.unit,
      };

      this.isBusy = true;
      this.$store
        .dispatch("saveCompanyLeaveType", form)
        .then((response) => {
          this.$emit("close", response.data);
        })
        .finally(() => (this.isBusy = false));
    },
    close(response) {
      return response;
    },
  },
};
</script>

<style scoped></style>
